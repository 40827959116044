$font-primary: 'Quicksand', Arial, sans-serif;

// Overrides
$grid-gutter-width: 40px !default;
$border-radius-base:  4px !default;
$padding-base-vertical: 14px !default;

$brand-primary: #FFC300 !default;
$brand-footer: #2C2C2C !default;



$brand-white: #fff;
$brand-black: #000;
$brand-darker: #444;
$brand-gray: #ccc;
$brand-lighter: #e9e9e9;
$brand-body-color: #e8e8e8;
$brand-selection-color: #f9f6f0;
$brand-overlay-color: #3b3d40;
$brand-bg-color: $brand-white;

$input-border-focus:  $brand-primary !default;
$form-group-margin-bottom:       30px !default;



// Mixin
@mixin translateX($translatex) {
	-moz-transform: translateX($translatex);
	-webkit-transform: translateX($translatex);
	-ms-transform: translateX($translatex);
	-o-transform: translateX($translatex);
	transform: translateX($translatex);
}
@mixin transition($transition) {
    -moz-transition:    all $transition ease;
    -o-transition:      all $transition ease;
    -webkit-transition: all $transition ease;
    -ms-transition: 		all $transition ease;
    transition:         all $transition ease;
}
@mixin inline-block() {
	display:-moz-inline-stack;
	display:inline-block;
	zoom:1;
	*display:inline;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
@mixin flex() {
	display: -webkit-box;
  	display: -moz-box;
  	display: -ms-flexbox;
  	display: -webkit-flex;
  	display: flex;
}
@mixin flexwrap() {
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
}

@font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon/icomoon.eot?srf3rx');
	src:url('../fonts/icomoon/icomoon.eot?srf3rx#iefix') format('embedded-opentype'),
		url('../fonts/icomoon/icomoon.ttf?srf3rx') format('truetype'),
		url('../fonts/icomoon/icomoon.woff?srf3rx') format('woff'),
		url('../fonts/icomoon/icomoon.svg?srf3rx#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

@mixin icomoon() {

	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

}

// Import
@import 'bootstrap/mixins';
@import 'bootstrap/variables';




/* =======================================================
*
* 	Template Style
*	Edit this section
*
* ======================================================= */

// Template Base
body {
	font-family: $font-primary;
	font-weight: 400;
	font-size: 15px;
	line-height: 1.8;
	color: rgba(0,0,0,.7);
	@media screen and (max-width: $screen-md) {
		font-size: 16px;
	}
}
a {
	color: $brand-primary;
	@include transition(.5s);
	&:hover, &:active, &:focus {
		color: $brand-primary;
		outline: none;
		text-decoration: none !important;
	}
}
p {
	margin-bottom: 1.5em;
}

h1, h2, h3, h4, h5, h6 {
	color: $brand-black;
	font-family: $font-primary;
	font-weight: 400;
	margin: 0 0 30px 0;
}
figure {
	margin-bottom: 2.5em;
	float: left;
	width: 100%;
	figcaption {
		font-size: 16px;
		width: 80%;
		margin: 20px auto 0px auto;
		color: lighten($brand-black, 70%);
		font-style: italic;
		font-family: $font-primary;
		@media screen and (max-width: $screen-xs) {
			width: 100%;
		}
	}
}
::-webkit-selection {
  color: $brand-white;
  background: $brand-primary;
}

::-moz-selection {
  color: $brand-white;
  background: $brand-primary;
}

::selection {
  color: $brand-white;
  background: $brand-primary;
}

#colorlib-page {
    width: 100%;
    overflow: hidden;
    position: relative;
}
#colorlib-aside {
	padding-top: 3em;
	padding-bottom: 40px;
	padding-left: 3em;
	padding-right: 3em;

	width: 20%;
	position: fixed;
	bottom: 0;
	top: 0;
	left: 0;
//	overflow-y: scroll;
	overflow-y: auto;
	z-index: 1001;
	background: $brand-footer;

	@include transition(.5s);
	@media screen and (max-width: $screen-lg) {
		width: 30%;
	}
	@media screen and (max-width: $screen-sm) {
		width: 270px;
		padding-left: 1em;
		padding-right: 1em;
		@include translateX(-270px);
		padding-top: 4em;
	}
	#colorlib-logo {
		text-align: right;
		font-weight: 700;
		font-size: 22px;
		text-transform: uppercase;
		display: block;
		width: 100%;
		@media screen and(max-width: $screen-sm){
			margin-bottom: 1em;
		}
		a{
			display: block;
			text-align: center;
			color: $brand-white;
			letter-spacing: 2px;
			padding-right: .3em;
		}
	}
	#colorlib-main-menu {
		ul {
			text-align: center;
			padding: 0;
			@media screen and (max-width: $screen-sm) {
				margin: 0 0 2em 0;
			}
			li {
				margin: 0 0 10px 0;
				padding: 0;
				list-style: none;
				font-size: 16px;
				font-weight: 500;
				text-transform: uppercase;
				letter-spacing: 1px;
				small {
					display: block;
					color: rgba(255,255,255,0.35);
					margin-top: 5px;
					@media screen and (max-width: $screen-sm) {
						font-size: 80%;
					}
				}
				&:nth-child(6){
					padding-top: 24px;
					margin-bottom: 10px;
					a {
						&:after {
							background-color: transparent;
						}
						}
				}
				&:nth-child(7){
					font-size: 22px;
					color: rgba(255,255,255,.8);
					margin: 20px auto 8px auto;
					padding-right: 5px;
					i {
						color: lighten($brand-primary, 15%)!important;
						margin-right: 5px;
					}
				}
				&:last-child{
					a {
						&:after {
							background-color: transparent;
						}
					}
				}
				a {
					color: rgba(255,255,255,.5);
					text-decoration: none;
					position: relative;
					padding: 10px 0;
					width: 170px;
					font-family: $font-primary;
					@include transition(.3s);
					&:after {
						content: "";
						position: absolute;
						height: 1px;
						bottom: 7px;
						left: 0;
						right: 0;
						background-color: $brand-primary;
						visibility: hidden;
						-webkit-transform: scaleX(0);
						-moz-transform: scaleX(0);
						-ms-transform: scaleX(0);
						-o-transform: scaleX(0);
						transform: scaleX(0);
						-webkit-transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
						-moz-transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
						-ms-transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
						-o-transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
						transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
					}
					&:hover {
						text-decoration: none;
						color: rgba(255,255,255,1);

						&:after {
							visibility: visible;
							-webkit-transform: scaleX(1);
							-moz-transform: scaleX(1);
							-ms-transform: scaleX(1);
							-o-transform: scaleX(1);
							transform: scaleX(1);
						}
					}
					&.btn-primary {
	background: $brand-primary;
	color: $brand-black;
	border: 2px solid $brand-primary;
	font-size: 18px;
	&:hover, &:focus, &:active {
		background: lighten($brand-primary, 15%)!important;
		border-color: lighten($brand-primary, 15%)!important;
		color: lighten($brand-black, 15%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-primary;
		border: 2px solid $brand-primary;
		&:hover, &:focus, &:active {
			background: $brand-primary;
			color: $brand-white;
		}
	}
}
				}
				&.colorlib-active {
					a {
						color: $brand-primary;
						&:after {
							visibility: visible;
							-webkit-transform: scaleX(1);
							-moz-transform: scaleX(1);
							-ms-transform: scaleX(1);
							-o-transform: scaleX(1);
							transform: scaleX(1);
						}
					}
				}
			}
		}
	}
	.colorlib-footer {
		position: absolute;
		bottom: 40px;
		right: 0;
		left: 0;
		font-size: 15px;
		text-align: center;
		font-weight: 400;
		color: rgba(255,255,255,.3);
		padding: 0 20px;
		width: 100%;
		@media screen and (max-height: 900px) {
			top: 600px;
	}
		@media screen and (max-width: $screen-sm) {
			position: static;
			padding: 0;
		}
		span {
			display: block;
		}
		ul {
			padding: 0;
			margin: 0;
			li {
				padding: 0;
				margin: 0;
				display: inline;
				list-style: none;
				a {
					color: rgba(255,255,255,.3);
					padding: 4px;
					&:hover, &:active, &:focus {
						text-decoration: none;
						outline: none;
						color: $brand-primary;
					}
				}
			}
		}
	}
}

#colorlib-main {
	width: 80%;
	float: right;
	@include transition(.5s);
	@media screen and (max-width: $screen-lg) {
		width: 70%;
	}
	@media screen and (max-width: $screen-sm) {
		width: 100%;
	}
}


#colorlib-hero {
	min-height: 500px;
	background: $brand-white url(../images/loader.gif) no-repeat center center;
	width: 100%;
	float: left;
	margin-bottom: 4em;
	clear: both;
	@media screen and (max-width: $screen-lg) {
		margin-bottom: 0em;
	}
	@media screen and (max-width: $screen-sm) {
		margin-bottom: 0em;
	}
	.flexslider {
		border: none;
		z-index: 1;
		margin-bottom: 0;

		.slides {
			position: relative;
			overflow: hidden;
			li {
				background-repeat: no-repeat;
				background-size: cover;
				background-position: bottom center;
				min-height: 400px;
				position: relative;
			}
		}
		.flex-control-nav {
			bottom: 20px;
			z-index: 1000;
			left: 20px;
			float: left;
			width: auto;
			@media screen and(max-width: $screen-sm){
				right: 20px;
				top: 100px;
			}
			li {
				display: block;
				margin-bottom: 10px;
				a {
					background: rgba(255,255,255,.5);
					box-shadow: none;
					width: 12px;
					height: 12px;
					cursor: pointer;
					&.flex-active {
						cursor: pointer;
						background: transparent;
						border: 2px solid $brand-primary;
					}
				}
			}
		}
		.flex-direction-nav {
			display: none;
		}
		.slider-text{
			display: table;
			opacity: 0;
			min-height: 500px;
			z-index: 9;
			> .slider-text-inner {
				display: table-cell;
				vertical-align: middle;
				min-height: 700px;
				padding: 2.5em;
				position: relative;
				.desc{
					position: absolute;
					bottom: 0;
					right: -15px;
					padding: 30px;
					// background: lighten($brand-black,97%);
					background: $brand-primary;
					@media screen and(max-width: $screen-sm){
						right: 0;
						left: 0;
					}
				}
				@media screen and (max-width: $screen-sm ) {
					text-align: center;
				}
				h1, h2 {
					margin: 0;
					padding: 0;
					color: rgba($brand-black,1);
					font-family: $font-primary;
				}
				h1 {
					margin-bottom: 20px;
					font-size: 44px;
					line-height: 1.3;
					font-weight: 300;
					@media screen and (max-width: $screen-sm) {
						font-size: 28px;
					}
				}
				h2 {
					font-size: 18px;
					line-height: 1.5;
					margin-bottom: 30px;
					font-weight: 300;
					font-family: $font-primary;
					a{
						color: rgba($brand-black,.8);
					}
				}
				.heading-section{
					font-size: 50px;
					@media screen and (max-width: $screen-sm){
						font-size: 30px;
					}
				}s
				.colorlib-lead{
					font-size: 20px;
					color: $brand-white;
				}
				p{
					margin-bottom: 0;
				}
				.btn{
					font-size: 12px;
					text-transform: uppercase;
					letter-spacing: 2px;
					color: $brand-black;
					padding: 10px 15px !important;
					border: none;
					border-radius: 2px;
					font-weight: 400;
					&.btn-learn{
						color: $brand-black;
						border: 1px solid $brand-white;
						background: $brand-white !important;
						&:hover{
							background: transparent !important;
							border: 1px solid $brand-black !important;
						}
					}
					@media screen and (max-width: $screen-sm ) {
						width: 100%;
					}
				}
			}
		}
	}

}

body {
	&.offcanvas {
		overflow-x: hidden;
		#colorlib-aside {
			@include translateX(0);
			width: 270px;
			background: $brand-footer;
			z-index: 999;
			position: fixed;
		}
		#colorlib-main, .colorlib-nav-toggle {
			top: 0;
			@include translateX(270px);
		}
	}
}

.colorlib-narrow-content{
	padding: 0 2.5em;
	@media screen and(max-width: $screen-sm){
		padding: 0 1em;
	}
}

.no-gutters{
	margin: 0;
	padding: 0;
}

.colorlib-blog,
.colorlib-work,
.colorlib-about,
.colorlib-services,
.colorlib-contact,
.colorlib-form{
	padding-top: 3em;
	padding-bottom: 4em;
	clear: both;
	width: 100%;
	display: block;
	@media screen and(max-width: $screen-sm){
		padding-top: 2em;
		padding-bottom: 3em;
	}
}




//COVER BG

.about-img,
.about-img-2,
.services-img{
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	position: relative;
}

.colorlib-heading {
	font-size: 18px;
	margin-bottom: 2em;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 2px;
	line-height: 1.8;
	position: relative;
	padding-bottom: 6px;
	&:after{
		position: absolute;
		bottom: -6px;
		left: 0;
		width: 60px;
		height: 4px;
		content: '';
		background: $brand-primary;
	}
	span {
		display: block;
	}
	@media screen and (max-width: $screen-sm ) {
		margin-bottom: 3em;
	}
}
.heading-meta{
	margin-bottom: 10px;
	display: block;
	font-size: 10px;
	text-transform: uppercase;
	color: lighten($brand-black,60%);
	font-weight: 500;
	letter-spacing: 2px;
}

//ABOUT
.about-img{
	width: 100%;
	height: 500px;
	margin-bottom: 30px;
	@media screen and (max-width: $screen-sm ) {
		height:300px
	}
}
.about-desc{
	h3{
		font-size: 18px;
		text-transform: uppercase;
		letter-spacing: 5px;
		font-weight: 500;
	}
}
.padding{
	padding: 1em;
}
.steps{
	display: block;
	position: relative;
	h3{
		font-weight: 400;
		margin-bottom: 20px;
		color: rgba(0,0,0,.7);
		font-size: 14px;
		letter-spacing: 3px;
		line-height: 1.5;
		text-transform: uppercase;
	}
	&:before{
		position: absolute;
		top: 20px;
		left: 0;
		right: 0;
		content: '';
		width: 100%;
		height: 1px;
		background: lighten($brand-black,90%);
		z-index: -1;
	}
	.icon{
		display: block;
		margin-bottom: 20px;
		position: relative;
		z-index: 0;
		span{
			display: table;
			width: 40px;
			height: 40px;
			border: 1px solid lighten($brand-black,90%);
			text-align: center;
			background: $brand-white;
			@include border-radius(50%);
			z-index: 1;
			i{
				display: table-cell;
				vertical-align: middle;
				color: $brand-black;
			}
		}
	}
	&:hover{
		i{
			color: $brand-primary !important;
		}
	}
	&.active{
		i{
			color: $brand-primary !important;
		}
	}
}


//PANEL
.fancy-collapse-panel .panel-default > .panel-heading {
	padding: 0;
}
.panel-heading a {
	padding: 15px 25px;
	display: block;
	width: 100%;
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 1px;
	text-transform: uppercase;
	background-color: $brand-black;
	color: $brand-white;
	position: relative;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	-ms-box-shadow: none !important;
	-o-box-shadow: none !important;
	box-shadow: none !important;

}
.panel{
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	-ms-box-shadow: none !important;
	-o-box-shadow: none !important;
	box-shadow: none !important;
	border: none;
}
.panel-heading a:after {
	font-family: "icomoon";
	content: "\ea0e";
	position: absolute;
	right: 20px;
	font-size: 20px;
	font-weight: 400;
	top: 50%;
	line-height: 1;
	margin-top: -10px;
}

.panel-heading a.collapsed:after {
content: "\ea1c";
}
.panel-heading a.collapsed{
	background: lighten($brand-black,98%);
	border: 1px solid lighten($brand-black,90%) !important;
	color: lighten($brand-black,20%);
}
.panel-body{
	padding: 1.5em;
	margin-top: 5px;
	border: 1px solid lighten($brand-black,90%) !important;
}


//SERVICES----------------------
.services-wrap{
	display: block;
	margin-bottom: 40px;
	z-index: 0;
	.services-img{
		height: 170px;
		display: block;
		// margin-bottom: 20px;
		z-index: -1;
	}
	.desc{
		width: 90%;
		z-index: 1;
		margin-top: -20px;
		background: lighten($brand-black,98%);
		padding: .8em .7em;
		h3 {
			font-weight: 500;
			margin-bottom: 20px;
			color: rgba(0,0,0,.7);
			font-size: 14px;
			letter-spacing: 2px;
			line-height: 1.5;
			margin-bottom: 0;
			text-transform: uppercase;
		}
	}
	h2{
		font-size:21px;margin-bottom:16px;margin-top:6px;font-weight: bold;
	}
	p{
		color: $brand-darker;
		margin-bottom: 14px;
		line-height: 1.4;
		@media screen and(max-width: $screen-md){
			font-size:14px
		}
	}
	table tr th,table tr td{
		color: $brand-darker
	}
}
.move-bottom{
	margin-top: 100px;
	@media screen and(max-width: $screen-sm){
		margin-top: 0;
	}
}

.colorlib-feature {
	text-align: left;
	width: 100%;
	float: left;
	margin-bottom: 20px;
	position: relative;
	&:last-child{
		margin-bottom:40px;
	}
	.colorlib-icon {
		position: absolute;
		top: 0;
		left: 0;
		width: 50px;
		height: 50px;
		display: table;
		text-align: center;
		background: rgba($brand-black,.03);
		@include border-radius(2px);
		i {
			display: table-cell;
			vertical-align: middle;
			color: $brand-black;
			font-size: 30px;
			height: 30px;
			@media screen and (max-width: $screen-lg) {
				font-size: 40px;
			}
		}
	}
	.colorlib-text {
		font-size: 14px;
		padding-left:80px;
		width: 100%;
		h2, h3 {
			margin: 0;
			padding: 0;
		}
		h3 {
			font-weight: 500;
			margin-bottom: 20px;
			color: rgba(0,0,0,.7);
			font-size: 14px;
			letter-spacing: 3px;
			text-transform: uppercase;
		}
		p{
			margin-bottom:0
		}
		a{
			color:$brand-black !important;
			text-decoration: underline;
		}
		a:hover{
			text-decoration:none
		}
	}
	&.colorlib-feature-sm {
		.colorlib-text {
			margin-top: 10px;
			font-size: 18px;
			&.tellink{
				margin-top:3px;
				margin-bottom:10px;
				font-size:22px;
			}
		}

//		.colorlib-icon {
//			i {
//				color: $brand-primary;
//				font-size: 40px;
//				@media screen and (max-width: $screen-lg) {
//					font-size: 28px;
//				}
//			}
//		}
	}
}


//.colorlib-feature-lg{
//	.colorlib-text {
//		h2, h3 {
//			margin: 0;
//			padding: 0;
//		}
//		h3 {
//			font-weight: 500;
//			margin-bottom: 20px;
//			color: rgba(0,0,0,.8);
//			font-size: 14px;
//			letter-spacing: .2em;
//			text-transform: uppercase;
//		}
//	}
//}



//BLOG
.blog-entry{
	width: 100%;
	float: left;
	background: $brand-white;
	margin-bottom: 3em;
	@media screen and(max-width: $screen-sm){
		margin-bottom: 3em;
	}
	.blog-img{
		width: 100%;
		float: left;
		overflow: hidden;
		position: relative;
		z-index: 1;
		margin-bottom: 25px;
		img {
			position: relative;
			max-width: 100%;
			-webkit-transform: scale(1.0);
			-moz-transform: scale(1.0);
			-ms-transform: scale(1.0);
			-o-transform: scale(1.0);
			transform: scale(1.0);
			@include transition(.3s);
		}
	}
	.desc{
		h3{
			font-size: 16px;
			margin-bottom: 25px;
			line-height: auto;
			font-weight: 500;
			text-transform: uppercase;
			letter-spacing: 1px;
			a{
				color: $brand-black;
				text-decoration: none;
			}
		}
		span{
			display: block;
			margin-bottom: 20px;
			font-size: 12px;
			color: rgba(0,0,0,.4) !important;
			text-transform: uppercase;
			letter-spacing: 3px;
			font-weight: 400;
			small{
				i{
					color: lighten($brand-black,60%);
				}
			}
		}
		.lead{
			font-size: 12px;
			text-transform: uppercase;
			letter-spacing: 2px;
			color: $brand-black;
		}
	}
	&:hover{
		.blog-img{
			img{
				-webkit-transform: scale(1.1);
				-moz-transform: scale(1.1);
				-ms-transform: scale(1.1);
				-o-transform: scale(1.1);
				transform: scale(1.1);
			}
		}
	}
}

.overlap{
	margin-top: 5em;
	background: $brand-white;
	padding: 30px;
	@media screen and(max-width: $screen-sm){
		margin-top: 2em;
	}
}
// PROJECT
.project{
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	position: relative;
	height: 300px;
	width: 100%;
	display: block;
	margin-bottom: 30px;
	.desc{
		position: absolute;
		top: 10px;
		bottom: 10px;
		left: 10px;
		right: 10px;
		background: rgba(0,0,0,.8);
		opacity: 0;
		@include transition(.3s);
		.con{
			padding: 20px;
			display: block;
			position: relative;
			height: 300px;
			.icon{
				position: absolute;
				bottom: 20px;
				left: 20px;
				right: 20px;
				span{
					display: inline;
					padding-right: 5px;
					a{
						color: lighten($brand-black,80%);
						padding: 5px;
						background: lighten($brand-black,20%);
						@include border-radius(4px);
					}
				}
				i{
					font-size: 16px;
					color: lighten($brand-black,80%);
				}
			}
		}
		h3{
			font-size: 16px;
			font-weight: 500;
			text-transform: uppercase;
			letter-spacing: 1px;
			-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
			transition: transform 0.3s, opacity 0.3s;
			-webkit-transform: translate3d(0, -15px, 0);
			transform: translate3d(0, -15px, 0);
			margin-bottom: 15px;
				color: $brand-white;
			a{
				color: $brand-white;
			}
		}
		span{
			display: block;
			color: lighten($brand-black,60%);
			font-size: 13px;
			-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
			transition: transform 0.3s, opacity 0.3s;
			-webkit-transform: translate3d(0, 15px, 0);
			transform: translate3d(0, 15px, 0);
			a{
				color: $brand-black;
			}
		}
		@media screen and(max-width: $screen-sm){
			opacity: 1;
			background: rgba(0,0,0,.7);
			top:auto;
			height: auto;
			h3{
				font-style: 28px;
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
				margin-bottom:0;
				color: $brand-white;
			}
			span{
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
								color: $brand-white;

			}
			.con{
				height:auto
			}
		}
	}
	&:hover{
		.desc{
			opacity: 1;
			h3{
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}
			span{
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}
		}
	}
}
.project-desc{
	h2{
		font-size: 16px;
		font-weight: 500;
		text-transform: uppercase;
		letter-spacing: 1px;
		margin-bottom: 15px;
	}
	span{
		display: block;
		color: lighten($brand-black,40%);
		font-size: 13px;
		margin-bottom: 15px;
		a{
			color: lighten($brand-black,40%);
		}
	}
	.icon{
		span{
			display: inline;
			padding-right: 5px;
		}
	}
	.btn-download{
		font-size: 13px;
		text-transform: uppercase;
		font-weight: 400;
		text-decoration: underline;
	}
}



//COUNTER
#colorlib-counter{
	position: relative;
	clear: both;
	margin-bottom: 0em;
}
.colorlib-counters {
	padding: 4em 0 5em 0;
	background-size: cover;
	background-attachment: fixed;
	background-position: center center;
	.overlay {
		z-index: 0;
		position: absolute;
		bottom: 0;
		top: 0;
		left: 0;
		right: 0;
		background: rgba($brand-black,.4);
	}
	.icon{
		width: 100px;
		height: 100px;
		display: table;
		margin: 0 auto;
		margin-bottom: 20px;
		i{
			display: table-cell;
			vertical-align: middle;
			width: 100px;
			height: 100px;
			font-size: 50px;
			color: $brand-primary;
			background: $brand-white;
			@include border-radius(50%);
		}
	}
	.counter-wrap{
		border: 1px solid red !important;
	}
	.colorlib-counter {
		font-size: 40px;
		display: block;
		color: rgba($brand-white,1);
		font-family: $font-primary;
		width: 100%;
		font-weight: 700;
		margin-bottom: .3em;
	}
	.colorlib-counter-label {
		color: rgba($brand-white,.7);
		text-transform: uppercase;
		font-size: 14px;
		letter-spacing: 5px;
		margin-bottom: 2em;
		display: block;
	}
}


.colorlib-lead {
	font-size: 18px;
	line-height: 1.5;
}
#get-in-touch{
	padding: 3em 0;
	.btn-learn{
		padding: 15px 30px !important;
		font-size: 13px;
		@include border-radius(30px);
	}
}


.colorlib-social {
	padding: 0;
	margin: 0;
	text-align: center;
	li {
		padding: 0;
		margin: 0;
		list-style: none;
		@include inline-block;
		a {
			font-size: 22px;
			color: $brand-black;
			padding: 10px;
			@include inline-block;
			@include border-radius(7px);
			@media screen and (max-width: $screen-sm) {
				padding: 10px 8px;
			}
			&:hover {
				color: $brand-primary;
			}
			&:hover, &:active, &:focus {
				outline: none;
				text-decoration: none;
				color: $brand-primary;
			}
		}
	}
}


#map {
	width: 100%;
	height: 700px;
	@media screen and (max-width: $screen-sm) {
		height: 200px;
	}
}

.colorlib-bg-color{
	background: lighten($brand-black,98%);
	position: relative;
}


.pagination{
	padding: 0;
	li{
		margin: 2px;
		a{
			color: $brand-black;
			background: transparent;
			color: $brand-black;
			margin: 2px;
			@include border-radius(0);
			&:first-child{
				@include border-radius(2px);
			}
			&:hover, &:focus{
				background: $brand-primary;
				color: $brand-white;
				border: 1px solid $brand-primary;
			}
			@media screen and(max-width: $screen-sm){
				padding: 7px 15px;
			}
		}
		&.active{
			a{
				background: $brand-primary;
				border: 1px solid $brand-primary !important;
				&:hover, &:focus{
					background: $brand-primary;
					color: $brand-white;
					border: 1px solid $brand-primary !important;
				}
			}
		}
	}
}

// Buttons
.btn {
	margin-right: 4px;
	margin-bottom: 4px;
	font-family: $font-primary;
	font-size: 12px;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 1px;
	@include border-radius(2px);
	@include transition(.5s);
	padding: 8px 15px!important;
	&.btn-sm {
		padding: 4px 15px!important;
	}
	&.btn-md {
		padding: 8px 20px!important;
	}
	&.btn-lg {
		padding: 18px 36px!important;
	}
	&:hover, &:active, &:focus {
		box-shadow: none!important;
		outline: none!important;
	}
}
.btn-primary {
	background: $brand-primary;
	color: $brand-black;
	border: 2px solid $brand-primary;
	font-size: 18px;
	&:hover, &:focus, &:active {
		background: lighten($brand-primary, 15%)!important;
		border-color: lighten($brand-primary, 15%)!important;
		color: lighten($brand-black, 15%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-primary;
		border: 2px solid $brand-primary;
		&:hover, &:focus, &:active {
			background: $brand-primary;
			color: $brand-white;
		}
	}
}
.btn-success {
	background: $brand-success;
	color: $brand-white;
	border: 2px solid $brand-success;
	&:hover, &:focus, &:active {
		background: darken($brand-success, 5%)!important;
		border-color: darken($brand-success, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-success;
		border: 2px solid $brand-success;
		&:hover, &:focus, &:active {
			background: $brand-success;
			color: $brand-white;
		}
	}
}
.btn-info {
	background: $brand-info;
	color: $brand-white;
	border: 2px solid $brand-info;
	&:hover, &:focus, &:active {
		background: darken($brand-info, 5%)!important;
		border-color: darken($brand-info, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-info;
		border: 2px solid $brand-info;
		&:hover, &:focus, &:active {
			background: $brand-info;
			color: $brand-white;
		}
	}
}
.btn-warning {
	background: $brand-warning;
	color: $brand-white;
	border: 2px solid $brand-warning;
	&:hover, &:focus, &:active {
		background: darken($brand-warning, 5%)!important;
		border-color: darken($brand-warning, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-warning;
		border: 2px solid $brand-warning;
		&:hover, &:focus, &:active {
			background: $brand-warning;
			color: $brand-white;
		}
	}
}
.btn-danger {
	background: $brand-danger;
	color: $brand-white;
	border: 2px solid $brand-danger;
	&:hover, &:focus, &:active {
		background: darken($brand-danger, 5%)!important;
		border-color: darken($brand-danger, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-danger;
		border: 2px solid $brand-danger;
		&:hover, &:focus, &:active {
			background: $brand-danger;
			color: $brand-white;
		}
	}
}

.btn-outline {
	background: none;
	border: 2px solid lighten($brand-black, 50%);
	font-size: 16px;
	@include transition(.3s);
	&:hover, &:focus, &:active {
		box-shadow: none;
	}
}

// Form Input Field
.form-box{
	border:1px solid #ccc;background:#fcf0cc;
	margin-top:40px;
	margin-bottom:40px
}
.form-control {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	box-shadow: none;
	background: transparent;
	border: transparent;
	background: lighten($brand-black,98%);
	height: 54px;
	font-size: 16px;
	font-weight: 400;
	@include transition(.3s);
	@include border-radius(0);

  	&:active, &:focus {
  		outline: none;
		box-shadow: none;
		border-color:transparent;
		background: lighten($brand-black,94%);
  }
  &.textarea_confirm {
  	min-height: 54px;
  	height: auto;
  }
}
form p{
	margin-bottom:6px;
	padding-top:12px;
	border-top: 1px solid #fff
}
input{
	margin-bottom:24px
}
#message {
	height: 130px;
}
.btngroup{
	text-align: center;
}
// Burger Menu
.colorlib-nav-toggle {
  cursor: pointer;
  text-decoration: none;
  &.active i {
		&::before, &::after {
			background: $brand-black;
		}
  }
  &.dark {
  	&.active i {
			&::before, &::after {
				background: $brand-black;
			}
	  }
  }
  &:hover, &:focus, &:active {
  	outline: none;
  	border-bottom: none!important;
  }
  i {
  	position: relative;
	  @include inline-block;
	  width: 30px;
	  height: 2px;
	  color: $brand-black;
	  font:bold 14px/.4 Helvetica;
	  text-transform: uppercase;
	  text-indent:-55px;
	  background: $brand-black;
	  transition: all .2s ease-out;
		 &::before, &::after {
	  	  content:'';
		  width: 30px;
		  height: 2px;
		  background: $brand-black;
		  position: absolute;
		  left:0;
		  @include transition(.2s);
	  }
  }
  &.dark {
  	i {
	  	position: relative;
		  color: $brand-black;
		  background: $brand-black;
		  transition: all .2s ease-out;
			 &::before, &::after {
			  background: $brand-black;
			  @include transition(.2s);
		  }
	  }
  }
}

.colorlib-nav-toggle i::before {
  top: -7px;
}
.colorlib-nav-toggle i::after {
  bottom: -7px;
}
.colorlib-nav-toggle:hover i::before {
  top: -10px;
}
.colorlib-nav-toggle:hover i::after {
  bottom: -10px;
}
.colorlib-nav-toggle.active i {
	background: transparent;
}
.colorlib-nav-toggle.active i::before {
  top:0;
  -webkit-transform: rotateZ(45deg);
     -moz-transform: rotateZ(45deg);
      -ms-transform: rotateZ(45deg);
       -o-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
}
.colorlib-nav-toggle.active i::after {
  bottom:0;
  -webkit-transform: rotateZ(-45deg);
     -moz-transform: rotateZ(-45deg);
      -ms-transform: rotateZ(-45deg);
       -o-transform: rotateZ(-45deg);
          transform: rotateZ(-45deg);
}
.colorlib-nav-toggle {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 9999;
  cursor: pointer;
  opacity: 1;
  visibility: hidden;
  padding:6px 14px 14px 14px;
	background: rgba(255,255,255,0.7);
	@include transition(.5s);
  	@media screen and (max-width: $screen-sm) {
  		opacity: 1;
		visibility: visible;
  	}

}


// Helper Class
.col-xxs-12 {
	@media screen and (max-width: 480px) {
		float: none;
		width: 100%;
	}
}
.row-bottom-padded-lg {
	padding-bottom: 7em;
	@media screen and (max-width: $screen-sm ) {
		padding-bottom: 1em;
	}
}
.row-bottom-padded-md {
	padding-bottom: 3em;
	@media screen and (max-width: $screen-sm ) {
		padding-bottom: 1em;
	}
}
.row-bottom-padded-sm {
	padding-bottom: 1em;
	@media screen and (max-width: $screen-sm ) {
		padding-bottom: 1em;
	}
}
.col-padding{
	padding: 10px !important;
}


.animate-box {
	.js & {
		opacity: 0;
	}
}

#sticky_item {
	overflow: hidden;
	@include transition(.3s);
	&.is_stuck {
		margin-top: 10px;

	}
}
.sticky-parent {
	@media screen and (max-width: $screen-md) {
		clear: both;
		height: inherit!important;
		float: left;
		padding-top: 30px;
	}
}
.sp-logo{
	display:none;
}
@media screen and (max-width: 768px) {
.sp-logo{
	display:block;
	position: absolute;
	top:80px;
	z-index: 99;
	left:calc(50% - 80px);
}
	a.btn.btn-primary{
		font-size:14px !important
	}}


.colorlib-contact{
	background:#FFC91A;color:#333;
	padding-bottom: 0;margin-bottom: 0;padding-top: 0em;
}
.gmap{
	padding:0;
	margin: 0 0 -8px 0;
	iframe {
										width: 100%;
										padding:0;
	margin: 0;
									}
}
.cr{
 text-align: center;
	p{
		margin-bottom:6px;
		a{
			color: $brand-black;
			text-decoration: underline;
		}
	}
}
.colorlib-about{
	padding-bottom:0.5em;
}

//EYECATCH
#colorlib-eyecatch{
	position: relative;
	clear: both;
	margin-bottom: 0em;
		padding: 8em 0 8em 0;
		@media screen and (max-width: $screen-sm ) {
		padding: 3em 0 2em 0;
	}
	.colorlib-narrow-content{

	}

}

//ABOUT
.colorlib-services{
	.colorlib-feature {
	text-align: left;
	width: 100%;
	float: left;
	margin-bottom: 20px;
	position: relative;
	&:last-child{
		margin-bottom:40px;
	}
	.colorlib-icon {
		position: absolute;
		top: 0;
		left: 0;
		width: 50px;
		height: 50px;
		display: table;
		text-align: center;
		background: rgba($brand-black,.00);
		@include border-radius(2px);
		i {
			display: table-cell;
			vertical-align: middle;
			color:$brand-primary;
			font-size: 40px;
			height: 30px;
			@media screen and (max-width: $screen-lg) {
				font-size: 40px;
			}
		}
	}
	.colorlib-text {
//		font-size: 14px;
//		padding-left:80px;
//		width: 100%;
font-size: 14px;
padding-left: 50px;
width: 100%;
padding-top: 16px;
		h2, h3 {
			margin: 0;
			padding: 0;
		}
		h3 {
			font-weight: 500;
			margin-bottom: 20px;
			color: rgba(0,0,0,.7);
			font-size: 18px;
			letter-spacing: 1px;
			text-transform: uppercase;
		}
		p{
			margin-bottom:0
		}
		a{
			color:$brand-black !important;
			text-decoration: underline;
		}
		a:hover{
			text-decoration:none
		}
	}
	&.colorlib-feature-sm {
		.colorlib-text {
			margin-top: 10px;
			font-size: 18px;
			&.tellink{
				margin-top:3px;
				margin-bottom:10px;
				font-size:22px;
			}
		}

//		.colorlib-icon {
//			i {
//				color: $brand-primary;
//				font-size: 40px;
//				@media screen and (max-width: $screen-lg) {
//					font-size: 28px;
//				}
//			}
//		}
	}
}
}

.mb40-20{
	margin-bottom:40px;
			@media screen and (max-width: $screen-sm ) {
	margin-bottom:20px;
	}
}
.mt40-20{
	margin-top:40px;
			@media screen and (max-width: $screen-sm ) {
	margin-bottom:20px;
	}
}

.g-inview iframe{width:100%;}
